import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useNavigation } from './NavigationContext';

interface PrivateRouteProps {
  element: React.ReactNode; // Accept a React node as the element to render
  role: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, role }) => {
  const token = localStorage.getItem('jwt');
  const { setErrors } = useNavigation();
  const navigate = useNavigate();


  if (!token) {
    // If no token, redirect to the login page
    return <Navigate to="/login" />;
  }



  return <>{element}</>; // Render the component passed as the element prop
};

export default PrivateRoute;